import React from 'react';
import { MapPin } from 'lucide-react';
import { Link } from 'react-router-dom';
import styles from '../DealBottomSheet/DealListV2.module.css';
import PricingV2 from "../Pricing/PricingV2";

const PriceComparison = ({ currentProduct, similarProducts, handleClose }) => {
    if (!similarProducts || similarProducts.length === 0) {
        return null;
    }

    // ClosingLink component to handle navigation with closing the bottom sheet
    const ClosingLink = ({ to, className, businessId, children }) => {
        const isCurrentBusinessPage = location.pathname === `/business/${businessId}`;

        if (isCurrentBusinessPage) {
            return (
                <div
                    className={className}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleClose();
                    }}
                >
                    {children}
                </div>
            );
        }

        return (
            <Link
                to={to}
                className={className}
                onClick={(e) => {
                    e.stopPropagation();
                    handleClose();
                }}
            >
                {children}
            </Link>
        );
    };

    // Function to get the lowest price from pricing array
    const getLowestPrice = (pricing) => {
        if (!pricing || pricing.length === 0) return null;
        const lowestPriceItem = pricing.reduce((lowest, current) =>
                                                   (current.price < lowest.price) ? current : lowest, pricing[0]);
        return lowestPriceItem.price;
    };

    // Function to calculate price difference percentage
    const calculatePriceDifference = (currentPrice, comparePrice) => {
        if (!currentPrice || !comparePrice) return null;
        const diff = ((comparePrice - currentPrice) / currentPrice) * 100;
        return diff.toFixed(1);
    };

    return (
        <div className={styles.priceComparisonContainer}>
            <h2 className={`flex items-center justify-center p-0 m-0`}>Same Product at Other Locations</h2>
            <div className={styles.comparisonItems}>
                {similarProducts.map((product) => {
                    const currentLowestPrice = getLowestPrice(currentProduct.pricing);
                    const comparisonLowestPrice = getLowestPrice(product.pricing);
                    const priceDiff = calculatePriceDifference(currentLowestPrice, comparisonLowestPrice);
                    const isPriceLower = priceDiff < 0;
                    const isPriceHigher = priceDiff > 0;

                    return (
                        <ClosingLink
                            key={product.id}
                            to={`/product/${product.id}`}
                            className={`${styles.comparisonItem} cursor-pointer`}
                            businessId={product.dispensary_id}
                        >
                            <div className={styles.comparisonItemContent}>
                                <div className={`flex text-white flex-wrap text-center justify-center cursor-pointer`}>
                                    <div className={`flex text-center justify-center items-center gap-1 cursor-pointer`}>
                                        <h3>{product.dispensary.name}</h3>

                                        <MapPin size={14}/>
                                        <span>{product.distance.toFixed(1)} mi</span>
                                    </div>
                                    <div className={`w-full cursor-pointer`}>
                                    <PricingV2 pricing={product.pricing} />
                                    </div>
                                </div>
                                <div className={`flex text-center text-white justify-center`}>

                                </div>
                            </div>
                        </ClosingLink>
                    );
                })}
            </div>
        </div>
    );
};

export default PriceComparison;
