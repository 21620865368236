import React from 'react';
import Lottie from 'react-lottie';

// Import all animation data
import AutomaticWeedCollector from '../../assets/lottie/AutomaticWeedCollector.json';
import CannabisApp from '../../assets/lottie/CannabisApp.json';
import CannabisDelivery from '../../assets/lottie/CannabisDelivery.json';
import CultivatingCannabis from '../../assets/lottie/CultivatingCannabis.json';
import Email01 from '../../assets/lottie/Email01.json';
import Email02 from '../../assets/lottie/Email02.json';
import Email03 from '../../assets/lottie/Email03.json';
import Email04 from '../../assets/lottie/Email04.json';
import Email05 from '../../assets/lottie/Email05.json';
import Email06 from '../../assets/lottie/Email06.json';
import Email07 from '../../assets/lottie/Email07.json';
import Email08 from '../../assets/lottie/Email08.json';
import Email09 from '../../assets/lottie/Email09.json';
import Email10 from '../../assets/lottie/Email10.json';
import Fire from '../../assets/lottie/Fire.json';
import IndustrialHemp from '../../assets/lottie/IndustrialHemp.json';
import Loading from '../../assets/lottie/Loading.json';
import ManDiggingNativeSoil from '../../assets/lottie/ManDiggingNativeSoil.json';
import Manuscript from '../../assets/lottie/Manuscript.json';
import Marijuana from '../../assets/lottie/Marijuana.json';
import MarijuanaLaw from '../../assets/lottie/MarijuanaLaw.json';
import MarijuanaWeed from '../../assets/lottie/MarijuanaWeed.json';
import OTP from '../../assets/lottie/OTP.json';
import OTP01 from '../../assets/lottie/Otp01.json';
import OTP02 from '../../assets/lottie/Otp02.json';
import Pluug from '../../assets/lottie/Pluug.json';
import SendingEmail from '../../assets/lottie/SendingEmail.json';
import SwipeUp from '../../assets/lottie/SwipeUp.json';
import Trending from '../../assets/lottie/Trending.json';
import WeedAddiction from '../../assets/lottie/WeedAddiction.json';
import WeedBag from '../../assets/lottie/WeedBag.json';
import WeedBag02 from '../../assets/lottie/WeedBag02.json';
import WeedBook from '../../assets/lottie/WeedBook.json';
import WeedEffects from '../../assets/lottie/WeedEffects.json';
import WeedEmoji from '../../assets/lottie/WeedEmoji.json';
import WeedPrice from '../../assets/lottie/WeedPrice.json';
import WeedRecovery from '../../assets/lottie/WeedRecovery.json';
import WeedWarning from '../../assets/lottie/WeedWarning.json';

// Animation variants object
const variants = {
    automaticWeedCollector: AutomaticWeedCollector,
    cannabisApp: CannabisApp,
    cannabisDelivery: CannabisDelivery,
    cultivatingCannabis: CultivatingCannabis,
    email01: Email01,
    email02: Email02,
    email03: Email03,
    email04: Email04,
    email05: Email05,
    email06: Email06,
    email07: Email07,
    email08: Email08,
    email09: Email09,
    email10: Email10,
    fire: Fire,
    industrialHemp: IndustrialHemp,
    loading: Loading,
    manDiggingNativeSoil: ManDiggingNativeSoil,
    manuscript: Manuscript,
    marijuana: Marijuana,
    marijuanaLaw: MarijuanaLaw,
    marijuanaWeed: MarijuanaWeed,
    otp: OTP,
    otp01: OTP01,
    otp02: OTP02,
    pluug: Pluug,
    sendingEmail: SendingEmail,
    swipeUp: SwipeUp,
    trending: Trending,
    weedAddiction: WeedAddiction,
    weedBag: WeedBag,
    weedBag02: WeedBag02,
    weedBook: WeedBook,
    weedEffects: WeedEffects,
    weedEmoji: WeedEmoji,
    weedPrice: WeedPrice,
    weedRecovery: WeedRecovery,
    weedWarning: WeedWarning
};

const LottieIcon = ({ variant, width = 400, height = 400, loop = true, autoplay = true, className }) => {
    const getAnimationData = () => {
        return variants[variant];
    };

    const defaultOptions = {
        loop,
        autoplay,
        animationData: getAnimationData(),
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <div className={`flex items-center justify-center ${className ? className:''}`}>
            <Lottie
                options={defaultOptions}
                height={height}
                width={width}
            />
        </div>
    );
};

export default LottieIcon;
