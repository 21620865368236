import React, { useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import styles from './MapComponent.module.css';
import FlowerMapMarker from "../../assets/map-markers/flower-marker.svg";
import './map.css';
import constants from "../../utils/constants";
import { useTheme } from '../ThemeContext/ThemeContext';
import {useNavigate} from "react-router-dom";
import {useHighlightDispensary} from "../../contexts/HighlightDispensaryProvider";
import {useGeolocation} from "../../contexts/GeolocationProvider";
import {stateProvinceAbbreviations} from "../../contexts/stateProvinceAbbreviations";
import {XCircleIcon} from "lucide-react";


const BusinessPopup = ({ business, isDarkMode, onBusinessClick, onClose, highlighted }) => {
    const handleClick = (e) => {
        e.preventDefault();
        if (onBusinessClick) {
            onBusinessClick(business);
        } else {
            window.location.href = `/business/${business.id}`;
        }
    };

    const handleCloseClick = (e) => {
        e.preventDefault();
        if(onClose){
            onClose()
        }
    }

    return (
        <div className={`map-box-wrap ${isDarkMode ? 'dark' : ''}`}>
            {!highlighted && (
                <XCircleIcon className="map-x" onClick={onClose}></XCircleIcon>
            )}
            <button onClick={handleClick} className="w-full text-left">
                <div className="map-logo-wrap">
                    <img src={business.logoImage} alt={business.name} />
                </div>
                <div className="map-info-wrap">
                    <h3>{business.name}</h3>
                    <p>{business.address1}</p>
                    <div className="flex items-center">
                        <StarRating rating={business.starRating} maxRating={5} size={15} />
                        <span className="ml-2">{business.starRating ? business.starRating:5}/5</span>
                    </div>
                </div>
            </button>
        </div>
    );
};

const StarRating = ({ rating, maxRating = 5, size = 15 }) => {
    const fillColor = '#98fb98';
    const backgroundColor = '#E5E7EB';

    return (
        <div className="flex">
            {[...Array(maxRating)].map((_, index) => {
                const newRating = rating <= 0 || !rating ? 5:rating;
                const fillPercentage = Math.max(0, Math.min(100, (newRating - index) * 100));
                return (
                    <svg
                        key={index}
                        width={size}
                        height={size}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <defs>
                            <linearGradient id={`starGradient-${index}-${fillPercentage}`}>
                                <stop offset={`${fillPercentage}%`} stopColor={fillColor} />
                                <stop offset={`${fillPercentage}%`} stopColor={backgroundColor} />
                            </linearGradient>
                        </defs>
                        <path
                            d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"
                            fill={`url(#starGradient-${index}-${fillPercentage})`}
                        />
                    </svg>
                );
            })}
        </div>
    );
};

const MapComponent = React.forwardRef(({ businesses, mapMarker, children, customClass, geoposition, customZoom, customHeight, onLoad, onBusinessClick, onMarkerClick, isProductMap }, ref) => {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const markersRef = useRef([]);
    const markerElementsRef = useRef({}); // Store marker elements by business ID
    const rootsRef = useRef([]);
    const popupsRef = useRef([]);
    const userLocationRef = useRef(null);
    const activePopupRef = useRef(null);
    const { isDarkMode } = useTheme();
    const [mapLoaded, setMapLoaded] = useState(false);
    const { highlightedDispensaryId } = useHighlightDispensary();

    // Add these for the Search This Area functionality
    const [showSearchAreaButton, setShowSearchAreaButton] = useState(false);
    const searchAreaButtonRef = useRef(null);
    const mapMovedByUserRef = useRef(false);
    const { changeGeoPosition } = useGeolocation();

    // Create a function to handle the Search This Area action
    const handleSearchThisArea = () => {
        if (!map.current) return;

        const center = map.current.getCenter();
        const newGeoposition = {
            latitude: center.lat,
            longitude: center.lng,
            // We'll need to get city, state, etc. via reverse geocoding
        };

        // Get location details using Nominatim
        fetch(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${center.lat}&lon=${center.lng}`
        )
            .then(response => response.json())
            .then(data => {
                const geoData = {
                    latitude: center.lat,
                    longitude: center.lng,
                    city: data.address.city || data.address.town || data.address.village || data.address.county,
                    state: data.address.state,
                    state_abbr: stateProvinceAbbreviations[data.address.state] || data.address.state,
                    country: data.address.country,
                    country_code: data.address.country_code ? data.address.country_code.toUpperCase() : 'Unknown'
                };

                console.log(geoData);
                // Update the GeolocationContext
                changeGeoPosition(true, geoData);

                // Hide the button after search
                setShowSearchAreaButton(false);
            })
            .catch(error => {
                console.error("Error in reverse geocoding:", error);
            });
    };

    // Add event listeners for map movement
    useEffect(() => {
        if (!map.current || !mapLoaded) return;

        const handleMapMoveEnd = () => {
            if (mapMovedByUserRef.current) {
                setShowSearchAreaButton(true);
                mapMovedByUserRef.current = false;
            }
        };

        const handleMapMoveStart = () => {
            // Only consider it a user move if it's not a programmatic move (like fitBounds)
            if (map.current && !map.current._zooming && !map.current._fitZoom) {
                mapMovedByUserRef.current = true;
            }
        };

        map.current.on('moveend', handleMapMoveEnd);
        map.current.on('movestart', handleMapMoveStart);
        map.current.on('dragstart', () => { mapMovedByUserRef.current = true; });

        return () => {
            if (map.current) {
                map.current.off('moveend', handleMapMoveEnd);
                map.current.off('movestart', handleMapMoveStart);
                map.current.off('dragstart');
            }
        };
    }, [mapLoaded]);

    // Create the Search This Area button
    useEffect(() => {
        if (!map.current || !mapLoaded) return;

        // Remove existing button if it exists
        if (searchAreaButtonRef.current) {
            searchAreaButtonRef.current.remove();
            searchAreaButtonRef.current = null;
        }

        if (showSearchAreaButton && !isProductMap) {
            // Create a container for the button
            const buttonContainer = document.createElement('div');
            buttonContainer.className = 'search-area-button-container';
            buttonContainer.style.position = 'absolute';
            buttonContainer.style.bottom = '0';
            buttonContainer.style.left = '50%';
            buttonContainer.style.transform = 'translate(-50%, -50%)';
            buttonContainer.style.zIndex = '1';

            // Create the button
            const button = document.createElement('button');
            button.className = `search-area-button ${isDarkMode ? 'dark' : ''}`;
            button.textContent = 'Search This Area';
            button.style.padding = '8px 16px';
            button.style.borderRadius = '20px';
            button.style.backgroundColor = isDarkMode ? '#333' : '#fff';
            button.style.color = isDarkMode ? '#fff' : '#333';
            button.style.border = isDarkMode ? '1px solid #555' : '1px solid #ccc';
            button.style.boxShadow = '0 2px 4px rgba(0,0,0,0.2)';
            button.style.cursor = 'pointer';
            button.style.fontWeight = 'bold';

            // Add event listener
            button.addEventListener('click', handleSearchThisArea);

            // Add the button to the container
            buttonContainer.appendChild(button);

            // Add the container to the map
            map.current.getContainer().appendChild(buttonContainer);

            // Store reference to the button container
            searchAreaButtonRef.current = buttonContainer;
        }
    }, [showSearchAreaButton, isDarkMode, mapLoaded]);

    // Function to display a popup for a business
    const showBusinessPopup = (businessId, highlighted) => {
        // First, remove any existing popup
        if (activePopupRef.current) {
            activePopupRef.current.remove();
            activePopupRef.current = null;
        }

        if (!businessId || !markersRef.current[businessId] || !map.current) return;

        const business = businesses.find(b => b.id === businessId);
        if (!business) return;

        // Get the marker's position
        const marker = markersRef.current[businessId];
        const lngLat = marker.getLngLat();

        // Create popup content with React
        const popupNode = document.createElement('div');
        const root = createRoot(popupNode);
        rootsRef.current.push(root);

        const handlePopupClose = () => {
            if (activePopupRef.current) {
                activePopupRef.current.remove();
                activePopupRef.current = null;
            }
        };

        root.render(
            <BusinessPopup
                business={business}
                isDarkMode={isDarkMode}
                onBusinessClick={onBusinessClick}
                onClose={handlePopupClose}
                highlighted={highlighted}
            />
        );

        // Create and show the popup
        const popup = new mapboxgl.Popup({
                                             offset: [0, -20],
                                             closeButton: false,
                                             maxWidth: '300px',
                                             className: isDarkMode ? 'dark' : ''
                                         })
            .setLngLat(lngLat)
            .setDOMContent(popupNode)
            .addTo(map.current);

        // Store reference to the active popup
        activePopupRef.current = popup;
        // Calculate if the marker is already visible in the current viewport
        const bounds = map.current.getBounds();
        const isVisible = bounds.contains(lngLat);
        // Optional: Center map on the marker

        if(!isVisible){
            map.current.flyTo({
                                  center: lngLat,
                                  zoom: map.current.getZoom(),
                                  duration: 500
                              });
        }


        return popup;
    };

    // Add CSS for highlighted marker
    useEffect(() => {
        // Add CSS rules for highlighted marker if they don't already exist
        if (!document.getElementById('highlighted-marker-style')) {
            const style = document.createElement('style');
            style.id = 'highlighted-marker-style';
            style.innerHTML = `
      @keyframes pulse {
        0% {
          filter: drop-shadow(0 0 4px rgba(83, 14, 143, 0.8));
        }
        50% {
          filter: drop-shadow(0 0 15px rgba(83, 14, 143, 1));
        }
        100% {
          filter: drop-shadow(0 0 4px rgba(83, 14, 143, 0.8));
        }
      }

      @keyframes pulseDark {
        0% {
          filter: drop-shadow(0 0 4px rgba(255, 255, 255, 0.8));
        }
        50% {
          filter: drop-shadow(0 0 15px rgba(255, 255, 255, 1));
        }
        100% {
          filter: drop-shadow(0 0 4px rgba(255, 255, 255, 0.8));
        }
      }

      .custom-marker.highlighted {
        transform: scale(1.3);
        animation: pulse 1.5s infinite ease-in-out;
        z-index: 999 !important;
      }

      .dark .custom-marker.highlighted {
        animation: pulseDark 1.5s infinite ease-in-out;
      }
    `;
            document.head.appendChild(style);
        }
    }, []);

    // Handle highlighted dispensary
    useEffect(() => {
        if (!mapLoaded || !map.current) return;

        // Reset all markers to normal state
        Object.keys(markerElementsRef.current).forEach(id => {
            const element = markerElementsRef.current[id];
            if (element && element.classList) {
                element.classList.remove('highlighted');
                if (markersRef.current[id]) {
                    const markerElement = markersRef.current[id].getElement();
                    if (markerElement) {
                        markerElement.style.zIndex = '';
                    }
                }
            }
        });

        //console.log("Highlighted ID:", highlightedDispensaryId);
        //console.log("Available Markers:", Object.keys(markersRef.current));

        // Highlight the selected marker if it exists
        // Highlight the selected marker if it exists
        if (highlightedDispensaryId && markersRef.current[highlightedDispensaryId]) {
            const element = markerElementsRef.current[highlightedDispensaryId];

            if (element && element.classList) {
                element.classList.add('highlighted');

                // Bring the marker to the front
                const markerElement = markersRef.current[highlightedDispensaryId].getElement();
                if (markerElement) {
                    markerElement.style.zIndex = '999';
                }

                // Show the popup for the highlighted business
                setTimeout(() => {
                    showBusinessPopup(highlightedDispensaryId, true);
                }, 300); // Small delay to ensure the map has finished any movement
            } else {
                console.log("Element not found for highlighting:", highlightedDispensaryId);
            }
        } else {
            // If no business is highlighted, remove any active popup
            if (activePopupRef.current) {
                activePopupRef.current.remove();
                activePopupRef.current = null;
            }
        }
    }, [highlightedDispensaryId, mapLoaded]);

    useEffect(() => {
        // When the map is initialized
        if (map) {
            if (onLoad) {
                console.log('MAPBOX LOADED');
                console.log(map);
                onLoad(map);
            }
        }
    }, [map, onLoad]);

    // Initialize MapBox
    useEffect(() => {
        if (map.current) return;

        mapboxgl.accessToken = constants.MAPBOX_ACCESS_TOKEN;

        const mapInstance = new mapboxgl.Map({
                                                 container: mapContainer.current,
                                                 style: isDarkMode ? 'mapbox://styles/mapbox/dark-v11' : 'mapbox://styles/mapbox/light-v11',
                                                 center: geoposition ? [geoposition.longitude, geoposition.latitude] : [0, 0],
                                                 zoom: geoposition ? 15 : 2
                                             });

        mapInstance.on('load', () => {
            setMapLoaded(true);
        });

        // Disable map rotation
        mapInstance.dragRotate.disable();
        mapInstance.touchZoomRotate.disableRotation();

        map.current = mapInstance;


        return () => {
            markersRef.current.forEach(marker => marker.remove());
            if (userLocationRef.current) {
                userLocationRef.current.remove();
            }
            map.current.remove();
        };
    }, [isDarkMode, geoposition]);

    useEffect(() => {
        if (!map.current || !mapLoaded) {
            console.log('Map not ready for zoom update');
            return;
        }


        const doZoom = () => {
            try {
                if (businesses?.length > 0) {

                    const bounds = new mapboxgl.LngLatBounds();

                    businesses.forEach(business => {
                        bounds.extend([
                                          business.lon ?? business.longitude,
                                          business.lat ?? business.latitude
                                      ]);
                    });

                    if (geoposition) {
                        bounds.extend([geoposition.longitude, geoposition.latitude]);
                    }

                    if (!bounds.isEmpty()) {

                        map.current.fitBounds(bounds, {
                            padding: { top: 45, bottom: 36, left: 36, right: 36 },
                            maxZoom: customZoom,
                            duration: 1000
                        });
                    }
                } else if (geoposition) {

                    map.current.flyTo({
                                          center: [geoposition.longitude, geoposition.latitude],
                                          zoom: customZoom,
                                          duration: 1000
                                      });
                } else {

                    map.current.easeTo({
                                           zoom: customZoom,
                                           duration: 1000
                                       });
                }
            } catch (error) {
                console.error('Error during zoom:', error);
            }
        };

        // Execute zoom after a short delay to ensure map is ready
        setTimeout(doZoom, 100);

        // Log final zoom after animation completes
        setTimeout(() => {
            if (map.current) {

            }
        }, 1200);

    }, [customZoom, mapLoaded, businesses, geoposition]);

    // Handle user location
    useEffect(() => {
        if (!map.current || !geoposition) return;
        if(onLoad){
            onLoad(map.current);
        }
        // Wait for both map style and source to be loaded
        const initializeUserLocation = () => {
            if (userLocationRef.current) {
                userLocationRef.current.remove();
            }

            const el = document.createElement('div');
            el.className = 'user-location-marker';

            const dot = document.createElement('div');
            dot.className = 'user-location-dot';
            el.appendChild(dot);

            const ring = document.createElement('div');
            ring.className = 'user-location-ring';
            el.appendChild(ring);

            // Create the marker with offset
            userLocationRef.current = new mapboxgl.Marker({
                                                              element: el,
                                                              anchor: 'center'  // This ensures the marker is centered on the location
                                                          })
                .setLngLat([geoposition.longitude, geoposition.latitude])


            userLocationRef.current = new mapboxgl.Marker(el)
                .setLngLat([geoposition.longitude, geoposition.latitude])
                .setPopup(
                    new mapboxgl.Popup({ offset: 25 })
                        .setHTML('<strong>📍 Your Location</strong>')
                )
                .addTo(map.current);

            if (geoposition.accuracy) {
                const accuracyCircleId = 'user-location-accuracy';

                // Remove existing accuracy circle if it exists
                if (map.current.getSource(accuracyCircleId)) {
                    map.current.removeLayer(accuracyCircleId);
                    map.current.removeSource(accuracyCircleId);
                }

                // Only add the accuracy circle if the map is fully loaded
                if (map.current.isStyleLoaded()) {
                    map.current.addSource(accuracyCircleId, {
                        type: 'geojson',
                        data: {
                            type: 'Feature',
                            geometry: {
                                type: 'Point',
                                coordinates: [geoposition.longitude, geoposition.latitude]
                            }
                        }
                    });

                    map.current.addLayer({
                                             id: accuracyCircleId,
                                             type: 'circle',
                                             source: accuracyCircleId,
                                             paint: {
                                                 'circle-radius': geoposition.accuracy,
                                                 'circle-color': '#FF4444',
                                                 'circle-opacity': 0.1,
                                                 'circle-stroke-width': 2,
                                                 'circle-stroke-color': '#FF4444'
                                             }
                                         });
                }
            }
        };

        // If the map style is already loaded, initialize immediately
        if (map.current.isStyleLoaded()) {
            initializeUserLocation();
        } else {
            // Otherwise wait for the style to load
            map.current.once('style.load', initializeUserLocation);
        }
    }, [geoposition, mapLoaded]);

    // Handle businesses
    useEffect(() => {
        if (!mapLoaded || !map.current || !businesses.length) return;

        // Clear existing markers and popups
        markersRef.current.forEach(marker => marker.remove());
        markersRef.current = [];
        markerElementsRef.current = {};

        const bounds = new mapboxgl.LngLatBounds();

        businesses.forEach(business => {
            const lngLat = [
                business.lon ?? business.longitude,
                business.lat ?? business.latitude
            ];

            // Create marker element
            const el = document.createElement('div');
            el.className = 'custom-marker';

            // Apply highlighted class if this is the highlighted dispensary
            if (business.id === highlightedDispensaryId) {
                el.classList.add('highlighted');
            }

            const markerUrl = mapMarker || FlowerMapMarker;
            el.style.backgroundImage = `url(${markerUrl})`;
            el.style.width = '35px';
            el.style.height = '41px';
            el.style.backgroundSize = 'contain';
            el.style.backgroundRepeat = 'no-repeat';
            el.style.backgroundPosition = 'center';

            el.addEventListener('click', (e) => {
                // Call the onMarkerClick callback if provided
                if (onMarkerClick) {
                    // We need to handle showing the popup ourselves
                    e.stopPropagation(); // Prevent default marker click behavior
                    onMarkerClick(business);

                    // Manually toggle the popup
                    if (activePopupRef.current) {
                        activePopupRef.current.remove();
                    }
                    showBusinessPopup(business.id);
                }
            });

            // Create popup content with React
            const popupNode = document.createElement('div');
            const root = createRoot(popupNode);
            rootsRef.current.push(root);
            root.render(
                <BusinessPopup
                    business={business}
                    isDarkMode={isDarkMode}
                    onBusinessClick={onBusinessClick}
                    onClose={() => popup.remove()}
                />
            );

            // Create popup
            const popup = new mapboxgl.Popup({
                                                 offset: [0, -20],
                                                 closeButton: false,
                                                 maxWidth: '300px',
                                                 className: isDarkMode ? 'dark' : ''
                                             }).setDOMContent(popupNode);

            // Create and add marker
            const marker = new mapboxgl.Marker({
                                                   element: el,
                                                   anchor: 'bottom'
                                               })
                .setLngLat(lngLat)
                .setPopup(popup)
                .addTo(map.current);

            // Store marker reference and element by business ID
            markersRef.current[business.id] = marker;
            markerElementsRef.current[business.id] = el;
            bounds.extend(lngLat);
        });

        // Add user location to bounds if available
        if (geoposition) {
            bounds.extend([geoposition.longitude, geoposition.latitude]);
        }

        // Fit bounds with padding if we have any points
        if (!bounds.isEmpty()) {
            map.current.fitBounds(bounds, {
                padding: { top: 36, bottom: 36, left: 36, right: 36 },
                maxZoom: customZoom ?? 13
            });
        } else if (geoposition) {
            map.current.flyTo({
                                  center: [geoposition.longitude, geoposition.latitude],
                                  zoom: customZoom ?? 10
                              });
        }
    }, [mapLoaded, businesses, mapMarker, isDarkMode, geoposition, customZoom, highlightedDispensaryId]);

    return (
        <div className={customClass ?? styles.MapContainer}>
            <div
                ref={mapContainer}
                className={customClass ?? styles.MapContainer}
                style={{ height: customHeight ?? 400, width: '100%' }}
            />
            {children}
        </div>
    );
});

export default MapComponent;
