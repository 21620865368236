import React, { memo } from 'react';
import {Navigate, Outlet, useNavigate} from 'react-router-dom';
import eventBus from "../../utils/eventBus";

const ProtectedRoute = memo(({ user, requiredRole }) => {
    const navigate = useNavigate();
    const hasRequiredRole = () => {
        if (!user || !user.role) return false;
        if (Array.isArray(requiredRole)) {
            return requiredRole.includes(user.role) || user.role === 'superadmin';
        }
        return user.role === requiredRole || user.role === 'superadmin';
    };

    if (!user) {
        eventBus.emit('unauthorized');
        return navigate('/');
    }

    if (requiredRole && !hasRequiredRole()) {
        return <Navigate to="/unauthorized" replace />;
    }

    return <Outlet />;
});

export default ProtectedRoute;
