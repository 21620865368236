import React, {useCallback, useRef, useState} from "react";
import styles from "../ProductList/ProductListV2.module.css";
import ProductActions from "../ProductActions/ProductActions";
import Pricing from "../Pricing/Pricing";
import {useNavigate} from "react-router-dom";
import DealBottomSheet from "../DealBottomSheet/DealBottomSheet";
import useApi from "../../utils/useApi";
import ProductImage from "../ProductImage/ProductImage";
import ProductDeals from "../ProductDeals/ProductDeals";
import DealBottomSheetV2 from "../DealBottomSheet/DealBottomSheetV2";
import ProductActionsV2 from "../ProductActions/ProductActionsV2";
import {useHighlightDispensary} from "../../contexts/HighlightDispensaryProvider";

const ProductItemV2 = ({ businessId, product, category, showTour, setShowTour, selectedWeight, selectedWeightUnit, priceFrom, priceTo }) => {
    const isTouchDevice = () => {
        return ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
    };

    const hasTouchSupport = isTouchDevice();

    const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
    const { voteProduct, likeProduct, trackProductView } = useApi();
    const [voteStatus, setVoteStatus] = useState(product.vote_status || 0);
    const [voteScore, setVoteScore] = useState(product.vote_score || 0);
    const [heatScore, setHeatScore] = useState(product.heat_score || 0);
    const navigate = useNavigate();
    const { setHighlightedDispensaryId } = useHighlightDispensary();

    const handleProductClick = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        console.log(product);

        setIsBottomSheetOpen(true);
        try {
            await trackProductView(product.id, product.dispensary_id);
        }
        catch (error) {
            console.error("Failed to track product view:", error);
        }
    };

    const handleMouseEnter = () => {
        if (hasTouchSupport || businessId) return;
        if (product.dispensary_id) {
            setHighlightedDispensaryId(product.dispensary_id);
        }
    };

    const handleMouseLeave = () => {
        if (hasTouchSupport || businessId) return;
        setHighlightedDispensaryId(null);
    };

    const closeBottomSheet = () => {
        setIsBottomSheetOpen(false);
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const ProductContent = ({ product }) => {
        // Add null check for product
        if (!product) {
            return null;
        }

        const capitalizeFirstLetter = (string) => {
            return string?.charAt(0).toUpperCase() + string?.slice(1);
        }

        const dealLabel = product?.product_deal[0]?.title || product?.product_deal[0]?.discountLabel || product?.product_deal[0]?.shortDisplayTitle;
        const dealLength = dealLabel ? dealLabel.length : 0;
        const dealLengthLimit = 21;



        return (
            <>
                {product.dateUpdated && (
                    <div className={styles.dateUpdated}>Updated: {product.dateUpdated}</div>
                )}
                <div className={`${styles.productInfoTop} tour-product-info-top`}>
                    <div className={`${styles.productImageWrap} tour-product-image`}>
                        <ProductImage product={product} category={category} customClass={styles.productImage}>
                            {product.distance_mi !== undefined && (
                                <span className={`${styles.dispensaryDistance} tour-distance`}>
                                {product.distance_mi.toFixed(1)} mi
                            </span>
                            )}
                        </ProductImage>

                    </div>
                    <div className={`${styles.productInfo} tour-product-details`}>
                        <div className={`${styles.productHeader} tour-product-header`}>
                            <h3 className={`${styles.productName} tour-product-name`}>{product.name}</h3>
                        </div>
                        {product.brand_name && (
                            <span className={`${styles.brandName} tour-brand-name`}>{product.brand_name}</span>
                        )}
                        <div className={`${styles.productMetadata} tour-product-metadata`}>
                            {/* {product?.product?.normalized_quantity && (
                                <span className={`${styles.productLabels} ${styles[product?.strain_type?.name?.toLowerCase()]} tour-product-quantity`}>
                                {product.product.normalized_quantity}
                            </span>
                            )} */}
                            {product?.strain_type?.name && (
                                <span className={`${styles.productLabels} ${styles.strainTypeLabel} ${styles[product.strain_type.name.toLowerCase()]} tour-strain-type`}>
                                {capitalizeFirstLetter(product.strain_type.name)}
                            </span>
                            )}
                            {product.product_category && (
                                <span className={`${styles.productLabels}

                                 tour-product-category`}>
                                {product.product_category}
                            </span>
                            )}
                            {product.thc_content_label && product.thc_content_label !== "-" && (
                                <span className={`${styles.thcContent} tour-thc-content`}>THC: {product.thc_content_label}</span>
                            )}
                            {product.cbd_content_label && product.cbd_content_label !== "-" && (
                                <span className={`${styles.cbdContent} tour-cbd-content`}>CBD: {product.cbd_content_label}</span>
                            )}

                            {product.dispensary && (
                                <span className={`${styles.dispensaryName} tour-dispensary-name`}>{product.dispensary.name}</span>
                            )}
                        </div>
                    </div>
                    <div className={styles.productActionsWrap}>

                        <ProductActionsV2 product={product}/>
                    </div>
                </div>

                <div className={`
                    ${styles.productInfoBottom}
                    tour-product-info-bottom
                    ${product.pricing?.length <= 1 && dealLength < dealLengthLimit ? styles.productInfoBottomNoMargin : ''}
                `}>
                    {product.pricing?.length <= 0 && (
                        <div className={styles.productImagePlaceholderWrap}></div>
                    )}

                    <div className={`${styles.productInfo} tour-product-price-info`}>
                        <div className={`${styles.priceInfo} tour-price-info ${product.pricing?.length > 0 ? 'flex-wrap':''}`}>
                            {(() => {
                                // Debug logs

                                return product.pricing?.length > 0 ? (
                                    <>
                                        <ProductDeals product={product}
                                                      wrapClass={'flex flex-row flex-wrap gap-0-25 w-full border-box box-border justify-center'}
                                                      styles={styles}
                                                      customClass={`${styles.discountWrapTop} `}
                                                      textClass={`dark:bg-red-900/30 border border-solid border-red-500 text-red-800 dark:text-red-200 p-2 rounded-lg`}
                                                      condition={product.pricing.length > 0}/>

                                        <Pricing
                                            pricing={product.pricing}
                                            selectedWeight={selectedWeight}
                                            selectedWeightUnit={selectedWeightUnit}
                                            priceFrom={priceFrom}
                                            priceTo={priceTo}
                                            deal={product.product_deal[0]}
                                            dealTitle={dealLabel}
                                        />

                                    </>
                                ) : (
                                           <div className={styles.priceSpans}>
                                               {product.sale_price ? (
                                                   <>
                                                        <span className={`${styles.originalPrice} tour-original-price`}>
                                                            ${parseFloat(product.price).toFixed(2)}
                                                        </span>
                                                       <span className={`${styles.salePrice} tour-sale-price`}>
                                                            ${parseFloat(product.sale_price).toFixed(2)}
                                                        </span>
                                                   </>
                                               ) : product.price ? (
                                                   <span className={`${styles.price} tour-price`}>
                                                        ${parseFloat(product.price).toFixed(2)}
                                                    </span>
                                               ) : null}
                                           </div>
                                       );
                            })()}
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            <div className={`${styles.productItem} tour-product-item`}
                 onClick={handleProductClick}
                 onMouseEnter={handleMouseEnter}
                 onMouseLeave={handleMouseLeave}>
                <ProductContent product={product}/>
            </div>


                <DealBottomSheetV2
                    selectedWeightUnit={selectedWeightUnit}
                    priceFrom={priceFrom}
                    priceTo={priceTo}
                    distance={product.distance_mi.toFixed(1)}
                    selectedWeight={selectedWeight}
                    businessId={businessId}
                    isOpen={isBottomSheetOpen}
                    onClose={closeBottomSheet}
                    productOrig={product}
                    deal={product?.product_deal[0]}
                    deals={product?.product_deal}
                />

        </>
    );
};

export default ProductItemV2;
