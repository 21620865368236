import React, {useEffect, useState, useCallback, useRef} from "react";
import ReactDOM from "react-dom";
import {Link, useLocation, useNavigate} from "react-router-dom";
import styles from "../DealBottomSheet/DealListV2.module.css";
import {X, MapPin, Phone, Clock} from "lucide-react";
import useApi from "../../utils/useApi";
import StarRating from "../StarRating/StarRating";
import ProductActions from "../ProductActions/ProductActions";
import Pricing from "../Pricing/Pricing";
import {HiDevicePhoneMobile} from "react-icons/hi2";
import useSafariNavBarState from "../../hooks/useSafariNavBarState";
import ProductDeals from "../ProductDeals/ProductDeals";
import MapComponent from "../MapComponent/MapboxMapComponent";
import Filters from "../Filters/Filters";
import {useGeolocation} from "../../contexts/GeolocationProvider";
import PullIndicator from "../PullIndicator/PullIndicator";
import useIsPhone from "../../hooks/useIsPhone";
import {useTheme} from "../ThemeContext/ThemeContext";
import GradientButton from "../../UI/GradientButton/GradientButton";
import ProductActionsV2 from "../ProductActions/ProductActionsV2";
import PriceComparison from "../PriceComparison/PriceComparison";

const ProductView = ({
                         product,
                         includeMap = true,
                         businessId,
                         isOpen,
                         onClose,
                         deal,
                         deals,
                         productOrig,
                         hideStore,
                         selectedWeight,
                         selectedWeightUnit,
                         priceFrom,
                         priceTo,
                         distance,
                         sheetRef,
                         similarProducts
                     }) => {
    const { isDarkMode } = useTheme();
    const isPhone = useIsPhone();
    const navigate = useNavigate();
    const location = useLocation();
    const { geoposition } = useGeolocation();
    const [customStyle, setCustomStyle] = useState({});
    const isNavExpanded = useSafariNavBarState();
    const [isIOSSafari, setIsIOSSafari] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [showDescription, setShowDescription] = useState(false);
    const { getProduct } = useApi();
    const useGradient = false;
    const labelStyle = useGradient ? { 'color': 'white' } : {};
    const [mapHeight, setMapHeight] = useState(135);
    const [mapZoom, setMapZoom] = useState(15);

    // DragToClose
    const [currentTranslate, setCurrentTranslate] = useState(0);
    const [canDragToClose, setCanDragToClose] = useState(false);

    // New state and refs for pull-down functionality
    const MAX_MAP_HEIGHT = 400;
    const MIN_MAP_HEIGHT = 135;
    const isMapExpanded = mapHeight === MAX_MAP_HEIGHT;
    const touchStartRef = useRef(0);
    const currentTouchRef = useRef(0);
    const isDraggingRef = useRef(false);
    const scrollContentRef = useRef(null);
    const mapContainerRef = useRef(null);
    const mapInstanceRef = useRef(null);

    // Dismiss Handler
    const [startY, setStartY] = useState(0);
    const [currentY, setCurrentY] = useState(0);
    const [isDragging, setIsDragging] = useState(false);

    // Handle map instance
    const handleMapLoad = useCallback((mapRef) => {
        mapInstanceRef.current = mapRef;
    }, []);

    // Resize map when height changes
    useEffect(() => {
        if (mapInstanceRef.current) {
            setTimeout(() => {
                mapInstanceRef.current.resize();
            }, 20); // Small delay to ensure transition is complete
        }
    }, [mapHeight]);

    const handleTouchStart = (e) => {
        if (!scrollContentRef.current || isMapExpanded) return;

        const touch = e.touches[0];
        touchStartRef.current = touch.clientY;
        currentTouchRef.current = touch.clientY;

        // Start dragging if we're at the top of the scroll or if the map is expanded
        if (scrollContentRef.current.scrollTop <= 0 || mapHeight > MIN_MAP_HEIGHT) {
            isDraggingRef.current = true;
        }
    };

    const handleTouchMove = (e) => {
        const touch = e.touches[0];
        const deltaY = touch.clientY - currentTouchRef.current;
        currentTouchRef.current = touch.clientY;

        if (!isDraggingRef.current || isMapExpanded) return;
        // If the map is expanded or we're trying to expand it, prevent default scroll
        if (mapHeight > MIN_MAP_HEIGHT || (deltaY < 0 && scrollContentRef.current?.scrollTop <= 0)) {
            //e.preventDefault();

            const newHeight = Math.min(
                Math.max(mapHeight + (deltaY * 3), MIN_MAP_HEIGHT),
                MAX_MAP_HEIGHT
            );
            setMapHeight(newHeight);
            setCanDragToClose(false);
        }
        // If we're at the top of the scroll and trying to expand, allow map expansion
        else if (scrollContentRef.current?.scrollTop === 0) {
            const newHeight = Math.min(
                Math.max(mapHeight + deltaY, MIN_MAP_HEIGHT),
                MAX_MAP_HEIGHT
            );
            setMapHeight(newHeight);
            setTimeout(function () {
                setCanDragToClose(true);
            }, 300);
        }
    };

    const handleTouchEnd = () => {
        if (!isDraggingRef.current || isMapExpanded) return;

        isDraggingRef.current = false;
        // Snap to min or max height based on current position
        const midPoint = (MAX_MAP_HEIGHT + MIN_MAP_HEIGHT) / 2;
        if (mapHeight > midPoint) {
            setMapHeight(MAX_MAP_HEIGHT);
        } else {
            setMapHeight(MIN_MAP_HEIGHT);
        }
    };

    const handleCloseTouchStart = (e) => {
        if (!canDragToClose) {
            return;
        }
        e.preventDefault(); // Prevent browser refresh
        setIsDragging(true);
        setStartY(e.touches[0].clientY);
        setCurrentTranslate(0);

        if (sheetRef.current) {
            sheetRef.current.style.transition = 'none';
        }
    }

    const handleCloseTouchMove = (e) => {
        if (!canDragToClose) return;

        const currentY = e.touches[0].clientY;
        const deltaY = currentY - startY;

        // Only allow pulling down, not up
        if (deltaY < 0) return;

        // Add resistance to the pull
        const translate = Math.min(deltaY * 0.5, 200);
        setCurrentTranslate(translate);

        if (sheetRef.current) {
            sheetRef.current.style.transform = `translateY(${translate}px)`;
        }
    }

    const handleCloseTouchEnd = (e) => {
        if (!canDragToClose) return;

        setIsDragging(false);

        if (sheetRef.current) {
            sheetRef.current.style.transition = 'all 0.3s ease';

            // If pulled down more than 100px, close the sheet
            if (currentTranslate > 15) {
                handleClose();
            } else {
                // Reset position
                sheetRef.current.style.transform = 'translateY(0)';
            }
        }
    }

    const handleClose = useCallback(() => {
        setIsClosing(true);

        document.body.style.overflowY = "auto";
        if (sheetRef.current) {
            sheetRef.current.style.transition = 'transform 0.3s ease-out';
            sheetRef.current.style.transform = 'translateY(100%)';
        }
        setTimeout(() => {
            setMapHeight(MIN_MAP_HEIGHT);
            setShowDescription(false);
            onClose();
        }, 300);
    }, [onClose]);

    // New wrapper component for Link that includes close functionality
    const ClosingLink = ({ to, className, businessId, children }) => {
        const isCurrentBusinessPage = location.pathname === `/business/${businessId}`;

        if (isCurrentBusinessPage) {
            return (
                <div
                    className={className}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleClose();
                    }}
                >
                    {children}
                </div>
            );
        }

        return (
            <Link
                to={to}
                state={{ previousPath: location.pathname + location.search }}
                className={className}
                onClick={(e) => {
                    e.stopPropagation();
                    handleClose();
                }}
            >
                {children}
            </Link>
        );
    };

    // New wrapper for external links
    const ClosingExternalLink = ({ href, className, children }) => (
        <a
            href={href}
            className={className}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => {
                e.stopPropagation();
                handleClose();
            }}
        >
            {children}
        </a>
    );

    const PhoneExternalLink = ({ href, className, children }) => (
        <a
            href={href}
            className={className}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            {children}
        </a>
    );

    const viewMore = (e) => {
        e.stopPropagation();
        setShowDescription(true);
    };

    const closeViewMore = (e) => {
        e.stopPropagation();
        setShowDescription(false);
    };

    const formatSchedule = (schedule) => {
        if (!schedule) return "Not available";
        const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        const today = daysOfWeek[new Date().getDay()];
        const todaySchedule = schedule[today];

        if (!todaySchedule) return "Closed now";

        const formatTime = (timeString) => {
            // Handle both formats: with T (ISO format) and without T (simple time)
            let hours, minutes;

            if (timeString.includes('T')) {
                // Handle ISO format like "2023-01-01T10:00:00"
                const [, time] = timeString.split('T');
                [hours, minutes] = time.split(':');
            } else {
                // Handle simple time format like "10:00"
                [hours, minutes] = timeString.split(':');
            }

            let hour = parseInt(hours, 10);
            const ampm = hour >= 12 ? 'PM' : 'AM';
            hour = hour % 12 || 12;
            return `${hour}:${minutes} ${ampm}`;
        };

        const openTime = formatTime(todaySchedule.open);
        const closeTime = formatTime(todaySchedule.close);
        return `${openTime} - ${closeTime}`;
    };

    const capitalizeFirstLetter = (string) => {
        if (!string) return '';
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const handleBusinessClick = (business) => {
        console.log(business);
        navigate(`/business/${business.id}`);
    }

    const handleMarkerClick = () => {
        setMapHeight(MAX_MAP_HEIGHT);
    }

    const handlePullIndicatorClick = () => {

        if (isMapExpanded) {
            setMapZoom(15);
            setMapHeight(MIN_MAP_HEIGHT);
        } else if (!isMapExpanded) {
            setMapHeight(MAX_MAP_HEIGHT);
            setMapZoom(20);

        }
    };

    const renderMap = () => {
        if (!product) return null;

        const mapMarker = Filters.getById(product.parentCategoryId)?.mapMarker;

        return (
            <div
                ref={mapContainerRef}
                style={{
                    height: `${mapHeight}px`,
                    transition: 'height 0.3s ease-out',
                    position: 'relative',
                    overflow: 'hidden'
                }}
            >
                <MapComponent
                    mapMarker={mapMarker}
                    customZoom={mapZoom}
                    businesses={[product.dispensary]}
                    geoposition={geoposition}
                    customHeight={`${mapHeight}px`}
                    customClass={styles.map}
                    onLoad={handleMapLoad}
                    onBusinessClick={handleBusinessClick}
                    onMarkerClick={handleMarkerClick}
                    isProductMap={true}
                >
                    <a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                        `${product.dispensary.name} ${product.dispensary.address1}`
                    )}`}
                       target={`_blank`}
                       className={`flex absolute top-2 left-4 rounded-tiny border-0 py-1 px-2 bg-brand-purple text-white text-[12px]`}>Get Directions</a>
                </MapComponent>

            </div>
        );
    };

    const marginRight = deal?.imageUrl ? 15 : 0;

    const renderProductDetails = () => {
        if (!product) return <p>Loading product information...</p>;

        return (
            <div className={styles.bottomSheetProductInfo}>
                <PullIndicator
                    isExpanded={isMapExpanded}
                    onClick={handlePullIndicatorClick}
                />
                <div className={`${styles.bottomSheetProductWrap} `}
                     onTouchStart={handleCloseTouchStart}
                     onTouchMove={handleCloseTouchMove}
                     onTouchEnd={handleCloseTouchEnd}>

                    <div className={`${styles.cardBackground} ${isDarkMode ? styles.cardBackgroundDarkOverlay : styles.cardBackgroundOverlay} ${isDarkMode ? styles.cardBackgroundDarkMode : ''} ${styles[product?.strain_type?.name.toLowerCase()]}`}></div>
                    <div className={`${styles.cardBackground} ${isDarkMode ? styles.cardBackgroundDarkOverlay : styles.cardBackgroundOverlay} `}></div>
                    <div className={styles.productContent}>
                        {renderDispensaryTopInfo()}
                        <div className={styles.bottomSheetProductImageWrapper}>
                            <img
                                src={product.formatted_thumbnail_url || 'https://product-assets.iheartjane.com/cdn-cgi/image/width=1200,fit=scale-down,format=auto,metadata=none/default-photos/flower/hybrid.png'}
                                alt={product.name}
                                style={{ marginRight: marginRight }}
                                className={styles.bottomSheetProductImage}
                            />
                            {(deal && !product.jane_id && deal.imageUrl) && (
                                <img src={deal.imageUrl} alt={deal.title} className={styles.bottomSheetDealImage}/>
                            )}
                        </div>

                        <div className={styles.bottomSheetDetailWrap}>
                            <div className={styles.bottomSheetProductDetails}>
                                <div className={styles.productNameDetails}>
                                    <h3 style={labelStyle}>{product.name}</h3>
                                    {product.brand?.name && (
                                        <span className={`${styles.bottomSheetBrandName} tour-brand-name`} style={labelStyle}>
                                        {product.brand.name}
                                    </span>
                                    )}
                                    <div className={styles.bottomSheetMetaVoteRow}>
                                        <div className={`${styles.productInfoLeft}`}>
                                            <div className={`${styles.productMetadata} tour-product-metadata`}>
                                                {product?.strain_type?.name && (
                                                    <span className={`${styles.productLabels} ${styles.strainTypeLabel} ${styles[product.strain_type.name.toLowerCase()]} tour-strain-type`}
                                                          style={labelStyle}
                                                    >
                                            {capitalizeFirstLetter(product.strain_type.name)}
                                        </span>
                                                )}
                                                {product.product_category && (
                                                    <span className={`${styles.productLabels} tour-product-category`}
                                                          style={labelStyle}
                                                    >
                                            {product.product_category}
                                        </span>
                                                )}
                                                {product.thc_content_label && product.thc_content_label !== "-" && (
                                                    <span className={`${styles.thcContent} tour-thc-content`}
                                                          style={labelStyle}
                                                    >
                                            {product.thc_content_label} THC
                                        </span>
                                                )}
                                                {product.cbd_content_label && product.cbd_content_label !== "-" && (
                                                    <span className={`${styles.cbdContent} tour-cbd-content`}
                                                          style={labelStyle}
                                                    >
                                            {product.cbd_content_label} CBD
                                        </span>
                                                )}
                                                {distance !== undefined && (
                                                    <span className={`${styles.dispensaryName} tour-distance`}
                                                          style={labelStyle}
                                                    >
                                            {distance} mi
                                        </span>
                                                )}
                                            </div>

                                        </div>
                                    </div>

                                </div>
                                <ProductActionsV2 product={product} />
                            </div>
                            <div className={styles.bottomSheetProductDeals}>
                                <div className={styles.bottomSheetMetaVoteRow}>
                                    {(deal && !product.jane_id) && (
                                        <div className={styles.bottomSheetDealInfo}>
                                            <ProductDeals product={product}
                                                          wrapClass={`flex flex-wrap flex-row justify-center`}
                                                          styles={styles}
                                                          strainType={``}
                                                          labelStyle={labelStyle}
                                                          includeFinePrint={true}
                                                          includeEndsAt={true}
                                                          customClass={`dark:bg-red-900/30 text-red-800 dark:text-red-300 p-3 mt-1 rounded-lg`}
                                                          condition={!product.jane_id}/>
                                        </div>
                                    )}
                                </div>

                                {product.pricing.length <= 0 && (
                                    <div className={styles.bottomSheetProductPrice}>
                                        {product.sort_price ? (
                                            <>
                                            <span className={styles.bottomSheetOriginalPrice}>
                                                ${parseFloat(product.price).toFixed(2)}
                                            </span>
                                                <span className={styles.bottomSheetSalePrice}>
                                                ${parseFloat(product.sort_price).toFixed(2)}
                                            </span>
                                            </>
                                        ) : (
                                             `$${parseFloat(product.price).toFixed(2)}`
                                         )}
                                    </div>
                                )}
                            </div>


                            <div className={styles.productDescription}>
                                {!showDescription ? (
                                    <div className={`text-[12px] fade-in`} onClick={viewMore}>
                                        {product?.product?.description && product?.product?.description?.length > 100
                                         ? `${product?.product?.description.slice(0, 100)}... `
                                         : product?.product?.description}
                                        {product?.product?.description?.length > 100 && (
                                            <span className={styles.moreLink}>
                                            More
                                        </span>
                                        )}
                                    </div>
                                ) : (
                                     <p className={`text-[12px]`} onClick={closeViewMore}>{product?.product?.description}</p>
                                 )}
                            </div>
                        </div>

                        {product.pricing.length > 0 && (

                            <Pricing
                                labelStyle={labelStyle}
                                deal={product?.product_deal[0]}
                                pricing={product.pricing}
                                selectedWeight={selectedWeight}
                                selectedWeightUnit={selectedWeightUnit}
                                priceFrom={priceFrom}
                                priceTo={priceTo}
                                customClass={styles.pricingTiers}
                            />
                        )}
                    </div>

                </div>

                {similarProducts && (
                    <PriceComparison currentProduct={product} similarProducts={similarProducts}/>
                )}


                <div className={`${styles.infoWrap}`}>
                    <h2 className={styles.attentionLabel}>Deal Terms</h2>
                    <p className={`text-sm ${styles.attentionText}`}>
                        Deals may appear active, but could be expired or out of stock. They are time-limited and subject to change or cancellation. pluug is not responsible for deal management and pricing. Taxes and fees may vary. Contact this location for any questions.
                    </p>
                </div>


            </div>
        );
    };

    const renderDispensaryInfo = () => {
        if (!product?.dispensary) return null;

        return (
            <div className={styles.bottomSheetDispensaryInfo}>
                <ClosingLink to={`/business/${product.dispensary_id}`} className={styles.dispensaryHeader} businessId={businessId}>
                    <img
                        src={product.dispensary.logoImage}
                        alt={product.dispensary.name}
                        className={styles.dispensaryLogo}
                    />
                    <div>
                        <h3>{product.dispensary.name}</h3>
                        <div className={styles.ratingWrapper}>
                            <StarRating
                                rating={product.dispensary.starRating ? product.dispensary.starRating : 5}
                                maxRating={5}
                                size={15}
                            />


                            <span>({product.dispensary?.numberOfReviews ? product.dispensary.numberOfReviews : 120} reviews)</span>
                        </div>
                    </div>
                </ClosingLink>

                <div className={styles.dispensaryDetails}>
                    <p>
                        <MapPin size={16}/>
                        <ClosingExternalLink
                            href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                                `${product.dispensary.name} ${product.dispensary.address1}`
                            )}`}
                        >
                            {product.dispensary.address1}, {product.dispensary.city},
                            {product.dispensary.state} {product.dispensary.zip}
                        </ClosingExternalLink>
                    </p>
                    <p>
                        <HiDevicePhoneMobile size={16}/>
                        <ClosingExternalLink href={`tel:${product.dispensary.phone}`}>
                            {product.dispensary.phone}
                        </ClosingExternalLink>
                    </p>
                    <p>
                        <Clock size={16}/>
                        {formatSchedule(product.dispensary.schedule)}
                    </p>
                </div>
            </div>
        );
    };

    const renderDispensaryTopInfo = () => {
        if (!product?.dispensary) return null;

        return (
            <div className={styles.dispensaryTopInfo}>
                <div className={styles.dispensaryTopHeader}>
                    <div className={`flex items-center sm:items-center sm:justify-start justify-center sm:w-[50%] w-[100%]`}>
                        <ClosingLink to={`/business/${product.dispensary_id}`} businessId={businessId}>
                            <img
                                src={product.dispensary.logoImage}
                                alt={product.dispensary.name}
                                className={`${styles.dispensaryTopLogo} w-[45px] h-[45px] sm:w-[39px] sm:h-[39px]`}
                            />
                        </ClosingLink>
                        <div className={`flex items-start flex-col`}>
                            <ClosingLink to={`/business/${product.dispensary_id}`} businessId={businessId}>
                                <h4 className={`p-0 m-0 mb-1`}>{product.dispensary.name}</h4>
                            </ClosingLink>
                            <div className={`flex sm:hidden flex-col`}>
                                <div className={`flex sm:hidden text-[10px] dark:text-[#fff] text-[#151515] sm:text-right text-center items-center gap-1 mt-1 mb-0`}>
                                    <MapPin size={12}/>
                                    <p
                                        className={`flex sm:hidden text-[10px] dark:text-[#fff] text-[#151515] m-0`}
                                        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                                            `${product.dispensary.name} ${product.dispensary.address1}`
                                        )}`}
                                    >
                                        {product.dispensary.address1}, {product.dispensary.city}, {product.dispensary.state} {product.dispensary.zip}
                                    </p>
                                </div>

                                <p className={`flex sm:hidden text-[10px] dark:text-[#fff] text-[#151515] sm:text-right text-center items-center gap-1 mt-1 mb-0`}>
                                    <HiDevicePhoneMobile size={12}/>

                                    <PhoneExternalLink className={`relative z-10`} target={`_blank`} href={`tel:${product.dispensary.phone}`}>
                                        {product.dispensary.phone}
                                    </PhoneExternalLink>
                                </p>
                                <p className={`flex sm:hidden text-[10px] dark:text-[#fff] text-[#151515] sm:text-right text-center items-center gap-1 mt-1 mb-0`}>
                                    <Clock size={12}/>

                                    {formatSchedule(product.dispensary.schedule)}
                                </p>
                            </div>

                        </div>
                    </div>

                    <div className={`${styles.dispensaryTopDetails} hidden sm:flex sm:flex-col gap-1 sm:items-end sm:w-[50%] w-[40%] justify-items-center sm:justify-items-end`}>
                        <div className={`sm:text-right text-center`}>
                            <div className={` text-[10px] dark:text-[#fff] text-[#151515] items-center flex`}>
                                <MapPin size={16}/>
                                <ClosingExternalLink

                                    href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                                        `${product.dispensary.name} ${product.dispensary.address1}`
                                    )}`}
                                >
                                    {product.dispensary.address1}, {product.dispensary.city}, {product.dispensary.state} {product.dispensary.zip}
                                </ClosingExternalLink>
                            </div>

                        </div>
                        <p className={`sm:text-right text-center flex items-center`}>
                            <HiDevicePhoneMobile size={16}/>
                            <ClosingExternalLink href={`tel:${product.dispensary.phone}`}>
                                {product.dispensary.phone}
                            </ClosingExternalLink>
                        </p>
                        <p className={`sm:text-right text-center flex items-center`}>
                            <Clock size={16}/>
                            {formatSchedule(product.dispensary.schedule)}
                        </p>
                    </div>

                </div>

            </div>
        );
    };

    return (
        <div ref={scrollContentRef}
             className={styles.bottomSheetScrollContent}
             onTouchStart={handleTouchStart}
             onTouchMove={handleTouchMove}
             onTouchEnd={handleTouchEnd}
        >
            {includeMap && (
                renderMap()
            )}

            <div className={styles.bottomSheetDealContent}>
                {renderProductDetails()}
                {/*  {renderDispensaryInfo()} */}
            </div>

        </div>
    );
};

export default ProductView;
