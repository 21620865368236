import React from 'react';
import styles from './Pricing.module.css';
import {useTheme} from "../ThemeContext/ThemeContext";
import {useDragScroll} from "../../hooks/useDragScroll";

const Pricing = ({
                     labelStyle,
                     deal,
                     dealTitle,
                     pricing,
                     selectedWeight = null,
                     selectedWeightUnit = null,
                     priceFrom = null,
                     priceTo = null,
                     customClass

                 }) => {
    const [dragToScrollRef] = useDragScroll();
    const { isDarkMode } = useTheme();
    // Filter to only show items with discounts
    let discountedPricing = pricing.filter(price => parseFloat(price.discount_price) < parseFloat(price.price)
    );
    discountedPricing = pricing;

    const dealLabel = deal?.shortDisplayTitle || dealTitle || deal?.title || deal?.discountLabel;
    const dealLength = dealLabel ? dealLabel.length : 0;
    const dealLimit = 14;

    // Only render if there are discounted items
    if (discountedPricing.length === 0) return null;

    // Check if any price is greater than 1/4oz (7g)
    const hasLargeWeight = selectedWeight >= 7;

    const hasLargePrice = priceFrom >= 40;

    return (
        <div ref={dragToScrollRef}
             className={`
            ${styles.pricingTiers} ${((hasLargeWeight || hasLargePrice) && discountedPricing.length > 4) ? styles.scrollEnd : ''}
            ${customClass}
            ${discountedPricing.length <= 2 ? styles.bigPricingBox : ''}
            ${discountedPricing.length <= 4 ? 'justify-center' : ''}
        `}>


            <div className={`${styles.pricingContent}
                ${discountedPricing.length == 1 ? styles.bigTierContent : ''}
                ${discountedPricing.length > 4 ? styles.flexGrow : ''}
                ${discountedPricing.length == 2 ? styles.bigTierContentHalf : ''}
            `}>
                {pricing.map((price) => {
                    let discountLabel = null;
                    let discountPercentage = 0;
                    let salePriceExists = true;

                    let discountPrice = price.discount_price ? (price.discount_price).toFixed(2) : null;

                    if (discountPrice === null) {
                        salePriceExists = false;
                        discountPrice = (price.price).toFixed(2);
                    } else {
                        discountPercentage = Math.round(
                            (1 - price.discount_price / price.price) * 100
                        );
                    }

                    if (discountPercentage <= 0 && dealLength <= dealLimit) {
                        discountLabel = dealLabel;
                    }

                    // Check if price matches the selected price range
                    const isPriceInRange = priceFrom > -1 && priceTo
                                           ? (discountPrice >= priceFrom && discountPrice <= priceTo) || (price.price >= priceFrom && price.price <= priceTo)
                                           : false;

                    // Check if weight matches either by unit string or numeric value
                    const isWeightSelected = selectedWeight && selectedWeightUnit
                                             ? price.unit == selectedWeightUnit || price.unit == selectedWeight + 'g'
                                             : false;

                    const isSelected = isWeightSelected || isPriceInRange;
                    const isGreyedOut = (selectedWeight || (priceFrom > -1 && priceTo)) && !isSelected;

                    return (
                        <div
                            key={price.id}
                            className={`${styles.priceTier}
                                ${(salePriceExists === false && (dealLength > dealLimit || dealLength <= 0)) ? styles.noSalePrice : ''}
                                ${discountedPricing.length == 1 ? styles.bigTierBox : ''}
                                ${discountedPricing.length == 1 && salePriceExists ? styles.bigTierBoxRow : ''}
                                ${discountedPricing.length == 2 ? styles.bigTierBoxHalf : ''}
                                ${discountedPricing.length > 4 ? styles.flexGrow : ''}
                                ${isSelected ? styles.selectedTier : ''}
                                ${isGreyedOut ? styles.greyedOut : ''}`}
                        >
                            <div className={styles.weight} style={labelStyle}>{price.unit}</div>
                            <div className={`
                                ${styles.priceWrap}
                                ${discountedPricing.length == 1 ? styles.priceWrapOne : ''}
                            `}>
                                {salePriceExists && (
                                    <div className={styles.originalPrice}>
                                        ${parseFloat(price.price).toFixed(2)}
                                    </div>
                                )}

                                <div className={`${styles.price} ${isDarkMode ? styles.priceDarkMode : ''} ${dealLength <= 0 && !salePriceExists ? styles.regularPrice : ''}`}>
                                    ${discountPrice}
                                </div>
                            </div>
                            {discountPercentage > 0 && (
                                <div className={styles.discountBadge}>
                                    {discountPercentage}% off
                                </div>
                            )}
                            {discountPercentage <= 0 && dealLength < dealLimit && dealLength > 0 && (
                                <div className={`${styles.discountBadge} ${isDarkMode ? styles.discountBadgeDarkMode : ''}`}>
                                    {dealLabel}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Pricing;
