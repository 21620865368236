import React from 'react';
import styles from "../DealList/DealList.module.css";

const ProductDeals = ({ product, styles, wrapClass, customClass, textClass, condition, includeFinePrint = false, includeEndsAt = false, labelStyle, strainType }) => {
    // Check if product has pricing and deals
    if (!product?.pricing || !product?.product_deal) {
        return null;
    }

    const dealLengthLimit = 21;

    return (
        <div className={`${wrapClass}`}>
            {condition &&
             product.product_deal.length > 0 &&
             product.product_deal.map((deal, index) => {
                 const dealLabel = deal?.title || deal?.discountLabel || deal?.shortDisplayTitle;
                 const fullDealLabel = (deal?.discountLabel && deal?.title && (deal.title != deal.discountLabel)) ? deal.title + " - " + deal.discountLabel:dealLabel;
                 const dealLength = fullDealLabel.length || 0;


                 return (
                     <div
                         key={deal.id}
                         className={`${styles.discountWrap}  ${customClass} ${styles[strainType]}`}
                     >
                        <span
                            className={`${styles.discountBadge} ${styles.discountBadgeTop} ${textClass}  tour-discount-badge box-border`}
                            style={labelStyle}
                        >
                          {fullDealLabel}
                        </span>
                         {/* {deal.endsAt && includeEndsAt && (
                             <p style={labelStyle}>Valid until: {new Date(deal.endsAt).toLocaleDateString()}</p>
                         )} */}
                         {deal?.finePrint?.length > 0 && includeFinePrint && (
                             <p className={styles.bottomSheetFinePrint} style={labelStyle}>{deal.finePrint}</p>
                         )}
                     </div>
                 );
             })}
        </div>
    );
};

export default ProductDeals;
