import React, {useState} from 'react';
import styles from "./ProductActionsV2.module.css";
import {TbArrowBigDown, TbArrowBigUp, TbHeart, TbHeartFilled} from "react-icons/tb";
import {IoArrowUpCircleOutline} from "react-icons/io5";
import useApi from "../../utils/useApi";
import ShareBottomSheet from "../ShareBottomSheet/ShareBottomSheet";
import {RxShare2} from "react-icons/rx";
import {useTheme} from "../ThemeContext/ThemeContext";


function ProductActionsV2({product}) {
    const [isShareOpen, setIsShareOpen] = useState(false);
    const [voteStatus, setVoteStatus] = useState(product.vote_status || 0);
    const [voteScore, setVoteScore] = useState(product.vote_score || 0);
    const [heatScore, setHeatScore] = useState(product.heat_score || 0);
    const { voteProduct, likeProduct } = useApi();


    const handleVote = async (e, vote) => {
        e.preventDefault();
        e.stopPropagation();
        try {
            const response = await voteProduct(product.id, vote);
            setVoteStatus(response.vote_status);
            setVoteScore(response.vote_score);
            setHeatScore(response.heat_score);
        }
        catch (error) {
            console.error("Failed to submit vote:", error);
        }
    };

    const handleLike = async () => {
        try {
            const response = await likeProduct(product.id);
            product.is_liked = response.like;
        }
        catch (error) {
            console.error("Failed to toggle the like status:", error);
        }
    };

    const handleShare2 = () => {
        // Display share container to all sharing networks with product image, link to product as query string ie. (?`product_id=product.id`, product name, brand and price and discount price from the first pricing in the product.pricing array
    }

    const handleShare = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsShareOpen(true);
    };

    return (
        <div className={`${styles.productActions} tour-product-actions`}>
            {/* <div className={`${styles.voteContainer}`}>
                <button
                    onClick={(e) => handleVote(e, -1)}
                    className={`${styles.voteButton} ${voteStatus === -1 ? styles.downvoted : ''} tour-downvote-button`}
                >
                    <TbArrowBigDown size={24}/>
                </button>
                <span className={`${voteScore > 0 ? styles.voteScorePositive : (voteScore < 0 ? styles.voteScoreNegative : styles.voteScore)} tour-vote-score`}>
                            {voteScore ?? 0}
                        </span>
                <button
                    onClick={(e) => handleVote(e, 1)}
                    className={`${styles.voteButton} ${voteStatus === 1 ? styles.upvoted : ''} tour-upvote-button`}
                >
                    <TbArrowBigUp size={24}/>
                </button>
            </div> */}
            <div className={`${styles.likeShareContainer}  tour-like-share-container`}>
                <button
                    onClick={(e) => handleShare(e)}
                    className={`${styles.likeShareBtn} tour-share-button`}
                >
                    <RxShare2 size={24}/>
                </button>
                <button
                    onClick={(e) => {
                        e.stopPropagation();
                        handleLike();
                    }}
                    className={`${styles.likeShareBtn} ${voteStatus === 1 ? styles.upvoted : ''} tour-like-button`}
                >
                    {product.is_liked ? (
                        <TbHeartFilled size={24} className={styles.liked}/>
                    ) : (
                         <TbHeart size={24}/>
                     )}
                </button>
            </div>

            <ShareBottomSheet product={product} open={isShareOpen} onOpenChange={setIsShareOpen} />
        </div>
    );
}

export default ProductActionsV2;

