import React, {useState, useEffect, useRef, useCallback} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet';  // Add this import
import styles from '../DealBottomSheet/DealListV2.module.css';
import useApi from "../../utils/useApi";
import ProductActions from "../ProductActions/ProductActions";
import Pricing from "../Pricing/Pricing";
import {useNavVisibility} from "../NavVisibilityContext/NavVisibilityContext";
import StarRating from "../StarRating/StarRating";
import {Clock, MapPin} from "lucide-react";
import {HiDevicePhoneMobile} from "react-icons/hi2";
import ProductImage from "../ProductImage/ProductImage";
import {useGeolocation} from "../../contexts/GeolocationProvider";
import Filters from "../Filters/Filters";
import MapComponent from "../MapComponent/MapboxMapComponent";
import PullIndicator from "../PullIndicator/PullIndicator";
import useIsPhone from "../../hooks/useIsPhone";
import ProductView from "../ProductView/ProductView";
import {useTheme} from "../ThemeContext/ThemeContext";

const ProductPreview = ({ handleShowHeader, handleShowSettingsHeader }) => {
    const {isDarkMode} = useTheme();
    const isPhone = useIsPhone();
    const navigate = useNavigate();
    const { geoposition } = useGeolocation();
    const { dispensarySlug, productSlug, id } = useParams();
    const [product, setProduct] = useState(null);
    const [productComparison, setProductComparison] = useState(null);
    const [showDescription, setShowDescription] = useState(false);
    const { setIsNavVisible } = useNavVisibility();
    const { getProduct, getProductPriceCompare } = useApi();

    const useGradient = true;
    const labelStyle = useGradient ? { 'color': 'white' } : {};
    const [mapHeight, setMapHeight] = useState(135);
    const [mapZoom, setMapZoom] = useState(15);
    // New state and refs for pull-down functionality
    const MAX_MAP_HEIGHT = 400;
    const MIN_MAP_HEIGHT = 135;
    const isMapExpanded = mapHeight === MAX_MAP_HEIGHT;
    const touchStartRef = useRef(0);
    const currentTouchRef = useRef(0);
    const isDraggingRef = useRef(false);
    const scrollContentRef = useRef(null);
    const mapContainerRef = useRef(null);
    const mapInstanceRef = useRef(null);
    const lastScrollYRef = useRef(0);

    useEffect(() => {
        handleShowHeader(true);
        handleShowSettingsHeader(false);
        setIsNavVisible(false);
    }, []);

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const productData = await getProduct(id);
                setProduct(productData);
            }
            catch (error) {
                console.error("Error fetching product:", error);
            }
        };

        const fetchPriceCompare = async () => {
            try {

                const result = await getProductPriceCompare(id);

                setProductComparison(result);
            }
            catch (error) {
                console.error("Error fetching product price comparison:", error);
            }
        };

        if (id) {
            fetchProduct();
            fetchPriceCompare();
        }
    }, [id, getProduct]);

    // Handle map instance
    const handleMapLoad = useCallback((mapRef) => {
        console.log('MAP CURRENT');
        console.log(mapRef);
        mapInstanceRef.current = mapRef;
    }, []);

    // Resize map when height changes
    useEffect(() => {
        if (mapInstanceRef.current) {
            setTimeout(() => {
                mapInstanceRef.current.resize();
            }, 20); // Small delay to ensure transition is complete
        }
    }, [mapHeight]);

    const handleTouchStart = useCallback((e) => {
        const touch = e.touches[0];
        touchStartRef.current = touch.clientY;
        currentTouchRef.current = touch.clientY;
        lastScrollYRef.current = window.scrollY;

        // Start dragging if we're at the top of the page or map is expanded
        if (window.scrollY <= 0 || mapHeight > MIN_MAP_HEIGHT) {
            isDraggingRef.current = true;
            //e.preventDefault(); // Prevent default only when we're going to handle the drag
        }
    }, [mapHeight]);

    const handleTouchMove = useCallback((e) => {
        if (!isDraggingRef.current) return;

        const touch = e.touches[0];
        const deltaY = touch.clientY - currentTouchRef.current;
        currentTouchRef.current = touch.clientY;

        // Determine if we should handle the touch event
        const isAtTop = window.scrollY <= 0;
        const isPullingDown = deltaY > 0;
        const isMapExpandable = mapHeight < MAX_MAP_HEIGHT;
        const isMapCollapsible = mapHeight > MIN_MAP_HEIGHT;

        if ((isAtTop && isPullingDown && isMapExpandable) ||
            (isMapCollapsible && !isPullingDown) ||
            mapHeight > MIN_MAP_HEIGHT) {

            e.preventDefault();

            // Calculate new map height
            const newHeight = Math.min(
                Math.max(mapHeight + deltaY, MIN_MAP_HEIGHT),
                MAX_MAP_HEIGHT
            );

            setMapHeight(newHeight);
        }
    }, [mapHeight]);

    const handleTouchEnd = useCallback(() => {
        if (!isDraggingRef.current) return;

        isDraggingRef.current = false;

        // Snap to min or max height based on current position
        const midPoint = (MAX_MAP_HEIGHT + MIN_MAP_HEIGHT) / 2;
        setMapHeight(mapHeight > midPoint ? MAX_MAP_HEIGHT : MIN_MAP_HEIGHT);
    }, [mapHeight]);

    // Add touch event listeners to document/window
    useEffect(() => {
        document.addEventListener('touchstart', handleTouchStart, { passive: false });
        document.addEventListener('touchmove', handleTouchMove, { passive: false });
        document.addEventListener('touchend', handleTouchEnd);

        return () => {
            document.removeEventListener('touchstart', handleTouchStart);
            document.removeEventListener('touchmove', handleTouchMove);
            document.removeEventListener('touchend', handleTouchEnd);
        };
    }, [handleTouchStart, handleTouchMove, handleTouchEnd]);

    if (!product) {
        return <div>Loading...</div>;
    }

    const firstPricing = product.pricing[0];
    const priceDisplay = firstPricing?.discount_price
                         ? `${firstPricing.discount_price} (was $${firstPricing.price})`
                         : firstPricing?.price || product.price;

    const deal = product.product_deal[0] ?? null;
    const capitalizeFirstLetter = (string) => {
        if (!string) return '';
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const formatSchedule = (schedule) => {
        if (!schedule) return "Not available";
        const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        const today = daysOfWeek[new Date().getDay()];
        const todaySchedule = schedule[today];

        if (!todaySchedule || !todaySchedule.isOpen) return "Closed today";

        const formatTime = (timeString) => {
            const [, time] = timeString.split('T');
            const [hours, minutes] = time.split(':');
            let hour = parseInt(hours, 10);
            const ampm = hour >= 12 ? 'PM' : 'AM';
            hour = hour % 12 || 12;
            return `${hour}:${minutes} ${ampm}`;
        };

        const openTime = formatTime(todaySchedule.open);
        const closeTime = formatTime(todaySchedule.close);
        return `${openTime} - ${closeTime}`;
    };

    const handleBusinessClick = (business) => {
        console.log(business);
        navigate(`/business/${business.id}`);
    }

    const handlePullIndicatorClick = () => {
        if (isMapExpanded) {
            setMapZoom(15);
            setMapHeight(MIN_MAP_HEIGHT);
        } else if (!isMapExpanded) {
            setMapHeight(MAX_MAP_HEIGHT);
            setMapZoom(20);

        }
    };

    const renderMap = () => {
        if (!product) return null;

        const mapMarker = Filters.getById(product.parentCategoryId).mapMarker;

        return (
            <div
                ref={mapContainerRef}
                style={{
                    height: `${mapHeight}px`,
                    transition: 'height 0.3s ease-out',
                    position: 'relative',
                    overflow: 'hidden'
                }}
            >
                <MapComponent
                    mapMarker={mapMarker}
                    customZoom={mapZoom}
                    businesses={[product.dispensary]}
                    geoposition={geoposition}
                    customHeight={`${mapHeight}px`}
                    customClass={styles.map}
                    onLoad={handleMapLoad}
                    onBusinessClick={handleBusinessClick}
                />

            </div>
        );
    };

    const renderFooter = () => {
        if (!product?.dispensary) return null;

        return (
            <div className={styles.bottomSheetFooter}>
                <div className={styles.bottomSheetButtons}>

                    <Link to="/" className={styles.bottomSheetHome}>
                        Home
                    </Link>

                    <Link
                        to={`/business/${product.dispensary_id}`}
                        className={styles.bottomSheetViewStore}
                    >
                        View Store
                    </Link>

                    <a
                        target="_blank"
                        href={product.link}
                        className={`${styles.bottomSheetGetDeal} ${isDarkMode ? styles.bottomSheetGetDealDark:''}`}
                    >
                        {deal && (<span>Get Deal</span>)}
                        {!deal && (<span>Buy Product</span>)}

                    </a>
                </div>
            </div>
        );
    };

    const renderDispensaryInfo = () => {
        if (!product?.dispensary) return null;

        return (
            <div className={styles.bottomSheetDispensaryInfo}>
                <Link to={`/business/${product.dispensary_id}`} className={styles.dispensaryHeader}>
                    <img
                        src={product.dispensary.logoImage}
                        alt={product.dispensary.name}
                        className={styles.dispensaryLogo}
                    />
                    <div>
                        <h3>{product.dispensary.name}</h3>
                        <div className={styles.ratingWrapper}>
                            <StarRating
                                rating={product.dispensary.starRating}
                                maxRating={5}
                                size={15}
                            />
                            <span>{product.dispensary.starRating.toFixed(1)}</span>
                            <span>({product.dispensary.numberOfReviews} reviews)</span>
                        </div>
                    </div>
                </Link>

                <div className={styles.dispensaryDetails}>
                    <p>
                        <MapPin size={16}/>
                        <Link
                            href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                                `${product.dispensary.name} ${product.dispensary.address1}`
                            )}`}
                        >
                            {product.dispensary.address1}, {product.dispensary.city},
                            {product.dispensary.state} {product.dispensary.zip}
                        </Link>
                    </p>
                    <p>
                        <HiDevicePhoneMobile size={16}/>
                        <Link href={`tel:${product.dispensary.phone}`}>
                            {product.dispensary.phone}
                        </Link>
                    </p>
                    <p>
                        <Clock size={16}/>
                        {formatSchedule(product.dispensary.schedule)}
                    </p>
                </div>
            </div>
        );
    };

    return (
        <div

            className={styles.previewContainer}
        >

            <ProductView
                includeMap={true}
                product={product}
                productOrig={product}
                deal={product.product_deal[0]}
                deals={product.product_deal}
                similarProducts={productComparison?.similar_products}
            />

            {renderFooter()}
            <div className={styles.bottomSheetDealContent}>




                {/* Meta tags for social media previews */}
                <Helmet>
                    <title>{`${product.name} by ${product.brand?.name || 'Unknown'}`}</title>
                    <meta name="description" content={product.description || `${product.name} available at ${product.dispensary?.name}`}/>

                    {/* Open Graph / Facebook */}
                    <meta property="og:type" content="product"/>
                    <meta property="og:title" content={`${product.name} by ${product.brand?.name || 'Unknown'}`}/>
                    <meta property="og:description" content={product.description || `${product.name} available at ${product.dispensary?.name}`}/>
                    <meta property="og:image" content={product.formatted_thumbnail_url}/>
                    <meta property="og:url" content={window.location.href}/>

                    {/* Twitter */}
                    <meta name="twitter:card" content="summary_large_image"/>
                    <meta name="twitter:title" content={`${product.name} by ${product.brand?.name || 'Unknown'}`}/>
                    <meta name="twitter:description" content={product.description || `${product.name} available at ${product.dispensary?.name}`}/>
                    <meta name="twitter:image" content={product.formatted_thumbnail_url}/>

                    {/* Additional product metadata */}
                    <meta property="product:price:amount" content={priceDisplay}/>
                    <meta property="product:price:currency" content="USD"/>
                    {product.brand?.name && <meta property="product:brand" content={product.brand.name}/>}
                </Helmet>
            </div>


        </div>
    );
};

export default ProductPreview;
